// This file is automatically generated by `generated_js` task. Any changes will be lost
/* tslint:disable */
export const GoCDVersion = {
  "version": "20.10.0",
  "buildNumber": "12356",
  "gitSha": "b16b6bc3f7ad3bb39e465ff2168d1cc66c95b4d5",
  "fullVersion": "20.10.0-12356",
  "formattedVersion": "20.10.0 (12356-b16b6bc3f7ad3bb39e465ff2168d1cc66c95b4d5)",
  "copyrightYear": "2020"
}


function stripLeadingPrefix(suffix: string, prefix: string) {
  if (suffix.startsWith(prefix)) {
      suffix = suffix.substring(1);
  }
  return suffix;
}

export function docsUrl(suffix: string = '') {
  return `https://docs.gocd.org/20.10.0/${stripLeadingPrefix(suffix, '/')}`
}

export function apiDocsUrl(suffix: string = '') {
  return `https://api.gocd.org/20.10.0/${stripLeadingPrefix(suffix, '#')}`
}
    
